import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_ACCOUNT_ROUTE, USER_SETNAME_ROUTE, BASE_CONTROL } from "../../../utils/consts";
import { Context } from "../../..";
import CropImageModal from "../../../components/CropImageModal";
import QrCodeModal from "../../../components/QrCodeModal";
import {
  changeUserAvatar,
  changeUsername,
  checkUsername,
  getInfo,
  saveUserBio,
  addToignoreLayer,
  listBlockUser,
  getLayerInfo,
  blockUser as blockApi,
  createCheckoutSession
} from "../../../http/userApi";
import { messagePermissions } from "../../../http/messageApi"

import {
  done,
  greenCheck,
  redCross,
  tickCirlce,
  defaultImage,
  purple_tick
} from "../../../images";
import { USERNAME_EXIST, isMobile, PREMIUM_USERNAME } from "../../../utils";
import { useMedia } from "../../../utils/use-media";
import Modal from "react-modal";
import Switch from "react-switch";
import { Helmet } from "react-helmet";
import Dropdown from "react-dropdown";

const SettingsProfile = ({ section, setLoading }) => {
  const { user } = useContext(Context);
  const isAuthPremium = user.user.is_premium;
  const bioInput = useRef();
  const headerRef = useRef();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [userBio, setUserBio] = useState(null);
  const [bioLength, setBioLength] = useState(0);
  const [newUsername, setNewUsername] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [usernameState, setUsernameState] = useState(false);
  const [userbioState, setUserbioState] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [isAvailable, setIsAvailable] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const [isCropPopup, setCropPopup] = useState(false);
  const [buttonText, setButtonText] = useState("Save");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const changeText = (text) => setButtonText(text);
  const [userId, setUserId] = useState(null);
  const [isOtherBlocked, setIsOtherBlocked] = useState(false);

  //// Modal Title
  const [modalIsOpen, setIsOpen] = useState(false);
  const [bioModalIsOpen, setBioModalIsOpen] = useState(false);
  const [title, setTitle] = useState(false);

  ///// Block user
  const [blockModal, setBlockModal] = useState(false);
  const [blockUser, setBlockUser] = useState([]);

  /////// Message Permissions ///////
  const [selectedOption, setSelectedOption] = useState({ label: "Everybody", value: "Everybody", className: "dropdown-options" });


  const [isPlusConfirmed, setIsPlusConfirmed] = useState(false);
  const [usernameType, setUsernameType] = useState({ isAvailable: false, isFreeUsername: false, isPaidUsername: false });


  let mobile = useMedia(isMobile);

  useEffect(() => {
    getInfo()
      .then((data) => {
        setUserInfo(data);
        setUserBio(data.bio);
        setAvatar(data.avatar ? data.avatar : null);
        setNewUsername(data?.username);
        if (data?.username) {
          setIsConfirmed(true);
        }
        user.setUser(data);
        const savedValue = data.messaging_permissions;
        const selectedOption = savedValue ? dropdownOptions.find(option => option.value === savedValue) : { label: "Everybody", value: "Everybody", className: "dropdown-options" };
        setSelectedOption(selectedOption);
      })
      .finally(
        setTimeout(function () {
          setLoading(false);
        }, 1000)
      );
  }, []);

  useEffect(() => {
    const storedValue = localStorage.getItem("isPrivate");
    if (storedValue) {
      setIsPrivate(JSON.parse(storedValue));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("isPrivate", isPrivate);
  }, [isPrivate]);

  if (userInfo && !userInfo.username && !userInfo.backup_email) {
    let url = USER_SETNAME_ROUTE;
    window.location.href = url;
  }

  /// handleportal for stripe payment

  const handlePortal = async () => {
    localStorage.setItem('newUsername', newUsername)
    try {
      const session = await createCheckoutSession(BASE_CONTROL);
      window.location.href = session.url;
    } catch (error) {
      console.log('error', error)
    }
  };


  const saveBio = async () => {
    await saveUserBio(userBio);
    setUserbioState(true);
  };

  const returnBio = () => {
    if (userInfo.bio) {
      setUserBio(userInfo.bio);
    } else {
      setUserBio("");
    }
    // setUserbioState(false);
    setIsOpen(false);
    setBioModalIsOpen(false);
  };

  const selectFile = async (e) => {
    setCropPopup(true);
    setSelectedImage(e.target.files[0]);
  };

  const onEditComplete = async (file) => {
    setCropPopup(false);
    let formData = new FormData();
    formData.append("img", file);
    const data = await changeUserAvatar(formData);
    user.setAvatar(data || null);
    setSelectedImage(file);
  };

  const openBioModal = () => {
    setBioModalIsOpen(true);
  };

  const closeBioModal = () => {
    setBioModalIsOpen(false);
    // setUserbioState(false); // Reset the bio editing state when closing the modal
  };

  const onBioChange = (e) => {
    const newBio = e.target.value;
    setUserBio(newBio);
    setBioLength(newBio.length);
    setUserbioState(true); // Enable the bio editing state
  };

  const onBioSave = async () => {
    await saveBio();
    closeBioModal();
    setUserbioState(false);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setTitle("");
  }

  function afterOpenModal() { }

  
  const changeUsernameTick = (str) => {
    // const isValidLength = isAuthPremium ? str.length >= 3 : str.length > 4;
    const isValidLength = str.length >= 3;
    const isWithinMaxLength = str.length <= 12;
    const containsNumber = /\d/.test(str);

    const noUppercase = !/[A-Z]/.test(str);
    const noSpecialChars = !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);

    let isFreeUsername = false;
    let isPaidUsername = false;

    if (str.length >= 3 && str.length <= 4) { // 
      if (containsNumber) {
        isFreeUsername = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars;
      } else {
        isPaidUsername = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars;
      }
    } else {
      isFreeUsername = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars;
    }
    const isAvailable = isFreeUsername || isPaidUsername;

    setIsAvailable(isAvailable);

    return { isAvailable , isFreeUsername, isPaidUsername };
  }


  const changeUserUsername = async (userNameChanged) => {
    console.log("change username function clickedc")
    const localUsername = localStorage.getItem('newUsername')

    const containsUppercase = (str) => /[A-Z]/.test(str);
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const validationResult = changeUsernameTick(userNameChanged);


    if (containsUppercase(userNameChanged)) {
      setIsOpen(true);
      setTitle(
        "Your username can only contain lowercase letters and/or numbers."
      );
    } else if (format.test(userNameChanged)) {
      setIsOpen(true);
      setTitle(
        "Your username can only contain lowercase letters and/or numbers."
      );
    } else if (userNameChanged.length < 3) {
      setIsOpen(true);
      setTitle(
        "Usernames with 3 or 4 letters only are premium usernames."
      );
    }
    else if (userNameChanged.length > 12) {
      setIsOpen(true);
      setTitle("Your username length must not exceed 12 characters.");

    } else if (validationResult.isAvailable || localUsername) {
      try {
        const data = await checkUsername(userNameChanged);
        if (data.status === 1) {
          if ((!isAuthPremium && userNameChanged.length >= 3 && userNameChanged.length <= 4 && !/\d/.test(userNameChanged))) {
            setIsOpen(true);
            setTitle(
              PREMIUM_USERNAME
            );
          } else {
            const res = await changeUsername(userNameChanged);
            const userData = userInfo;
            userData["username"] = userNameChanged;
            setUserInfo(userData);
            user.setUser(userInfo);
            setIsAvailable(false);
            setIsConfirmed(true);
          }
        } else {
          setIsOpen(true);
          setTitle(data.message);
          setIsAvailable(false);
        }
        setUsernameState(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleUsernameChange = (newUsername) => {
    const result = changeUsernameTick(newUsername);
    setUsernameType(result);
  };

  const { isFreeUsername, isPaidUsername } = usernameType;

  /* const changeUsernameTick = (str) => {
   // const isValidLength = isAuthPremium ? str.length >= 3 : str.length > 4;
   const isValidLength = str.length >= 3;
   const isWithinMaxLength = str.length <= 12;
   let noNumber = true;
   if(str.length >= 3 && str.length <= 4){
     noNumber = !/\d/.test(str)
   }
   const noUppercase = !/[A-Z]/.test(str);
   const noSpecialChars = !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);

   const isAvailable = isValidLength && isWithinMaxLength && noUppercase && noSpecialChars && noNumber;
   setIsAvailable(isAvailable);
 };*/

  ////////////////////////////////// states to manage external search ///////////////////////////////////////////////////////////////

  const dynamicRobotsTxtManager = async (value) => {
    console.log("privacy value", value);
    try {
      const response = await addToignoreLayer(value);
      setIsPrivate(value);
    } catch (error) {
      console.error("Error adding to ignoreLayer:", error);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "transparent",
      height: "auto",
      border: "0",
    },
  };

  const onConfirm = () => {
    if (user?.isAuth) {
      navigate(USER_ACCOUNT_ROUTE);
    } else {
      headerRef?.current?.callBack();
    }
  };

  ////////////  Block user //////////////////////

  const loadBlockUsers = async (e) => {
    setBlockModal(true);
    const data = await listBlockUser()
      .then((data) => {
        setBlockUser(data?.data);
      })
      .finally();
  };

  const searchBlockUser = (data) => {
    if (data && data.length >= 3) {
      const filtered = blockUser.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.includes(data)
        )
      );
      setBlockUser(filtered);
    } else {
      loadBlockUsers();
    }
  };

  function closeBlockModal() {
    setBlockModal(false);
  }

  const unblockUser = async (id) => {
    const res = await blockApi(id);
    if (res) {
      setIsOtherBlocked(false);
      if (blockUser) {
        const filtered = blockUser.filter((entry) => !(entry.id == id));
        setBlockUser(filtered);
      }
    }
  };

  /////////////////////////////// message permissions ///////////////////////////

  const allDropdownOptions = [
    { label: "Everybody", value: "Everybody", className: "dropdown-options" },
    { label: "Layers only", value: "Layers only", className: "dropdown-options" },
    { label: "Bookmarks only", value: "Bookmarks only", className: "dropdown-options" },
    { label: "Nobody", value: "Nobody", className: "dropdown-options" },
  ];

  const dropdownOptions = isAuthPremium ? allDropdownOptions : allDropdownOptions.filter(option => option.value !== "Bookmarks only");

  const handleSelect = async (option) => {
    setSelectedOption(option);
    try {
      const response = await messagePermissions(option.value);
      console.log("API response:", response);
    } catch (error) {
      console.error("Error updating messaging permissions:", error);
    }
  }

  useEffect(() => {
    if (isAuthPremium) {
      const localUsername = localStorage.getItem('newUsername')
      if (localUsername) {
        setNewUsername(localUsername);

        setUsernameState(true);
        setIsConfirmed(false);

        changeUserUsername(localUsername);
        localStorage.removeItem("newUsername");
      }
      setIsPlusConfirmed(true);
    }
  }, [isAuthPremium]);

  return (
    <>
      <Modal
        isOpen={bioModalIsOpen}
        onRequestClose={closeBioModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "transparent",
            height: "auto",
            border: "0",
          },
        }}
        contentLabel="Bio Edit Modal"
      >
        <div className="w-[540px] max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[10px] border-[#DAD7D4] border-[1px] radius-[5px] rounded-[10px] text-center">
          <h2 className="block justify-left mb-[20px] leading-none text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
            Edit your Bio
          </h2>
          <div className="justify-left mb-[40px] leading-none text-base text-[#645F5B] dark:text-white">
            Maximum 170 characters.
          </div>
          <textarea
            className="bg-transparent border border-[#589ED5] text-[#645F5B] dark:text-[#fff] w-full resize-none outline-0 p-2 h-[80px] mb-[20px] min-h-[100px] textBoxNoneBlur"
            onChange={(e) => setUserBio(e.target.value)}
            value={userBio || ""}
            placeholder="Write about yourself"
            maxLength={170}
          />
          <div className="flex justify-between min-w-100">
            <button
              className={`border border-[#EAEAEA]${userbioState ? "[#5FC650]" : "[#EAEAEA]"
                } text-[${userbioState ? "#5FC650" : "#645F5B"}]
              message-btn btn-delete p-[10px] m-[5px] border-[1px] border-[#0A85D1] rounded-[5px] mt-0 text-[#0A85D1] text-16px font-medium flex items-center`}
              onClick={saveBio}
            >
              Save
              <img
                className="ml-[6px]"
                height={10}
                width={10}
                src={done}
                alt="done"
              />
            </button>
            <button
              className=" btn-cancel p-[10px] text-[#645f5b] m-[5px] border-[1px] border-[#EAEAEA] dark:border-[#EAEAEA] dark:text-[#ffffff] rounded-[5px] mt-0 text-[#645F5B] text-16px font-medium flex items-center"
              onClick={returnBio}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className=" max-w-full bg-[#F8F8F8] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[10px]
            border-[#DAD7D4] border-[1px] radius-[5px] rounded-[10px] text-center"
        >
          <h2 className=" block mb-[20px] justify-left text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
            {" "}
            {title == USERNAME_EXIST
              ? "Username already exists"
              : (title == PREMIUM_USERNAME ? PREMIUM_USERNAME : "Username parameters")}{" "}
          </h2>
          <p className="block justify-left mb-[40px] text-base text-[#645F5B] dark:text-white">
            {title == USERNAME_EXIST
              ? "Please choose another username."
              : (title == PREMIUM_USERNAME ? "Usernames with 3 or 4 letters only are premium usernames."
                : `Lowercase letters or numbers and between 3 and 12 characters (usernames with 3 or 4 letters only are for Plus Layers).`)}{" "}
            {/* : (isAuthPremium ? "Usernames can only contain lowercase letters or numbers and must be between 3 and 12 characters." : "Usernames can only contain lowercase letters or numbers and must be between 4 and 12 characters."))}{" "} */}
          </p>

          <div className="flex justify-between">
            {(!isAuthPremium && title == PREMIUM_USERNAME &&
              <button
                className="text-base font-regular p-[5px] text-[#9D34DA] border-[1px] border-[#9D34DA] dark:border-[#9D34DA] dark:text-[#9D34DA] rounded"
                onClick={handlePortal}
              >
                Get Plus
              </button>
            )}
            <div className="flex-1 flex justify-end">
            <button
              className="message-btn btn-cancel text-base p-[5px] text-[#645f5b] border-[1px] border-[#eaeaea] dark:border-[#ffffff] dark:text-[#ffffff] rounded"
              onClick={() => {
                closeModal();
              }}
            >
              Back
            </button>
            </div>
          </div>
        </div>
      </Modal>

      <div ref={section.ref} className=" border-EA w-full mt-10 lg:mt-0">
        <h1 className=" text-left leading-[24px] py-[5px] md:text-2xl text-xl font-bold text-[#645F5B] dark:text-[#EAEAEA]">
          Profileabc
        </h1>
        <div className="flex flex-col mt-[55px]">
          <h2 className=" leading-[24px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-[#EAEAEA]">
            Username 
          </h2>

          <div className="flex flex-col justify-between mt-[40px]">
            <p className="leading-[19px] text-left text-base text-[#645F5B] dark:text-[#EAEAEA]">
              My username and URL extension.
            </p>
            <div className="flex flex-row  w-full lg:w-[450px] h-[35px] mt-[20px]">
              <span className=" bg-EA py-[9px] px-[11px] h-[35px] rounded-l-[5px] text-[#645F5B] leading-none">
                layer.page/
              </span>
              <div className="bg-[#D6D6D633] rounded-r-[5px] border border-[#EAEAEA] relative h-[35px] flex flex-row w-full">
                <input
                  type="text"
                  className="outline-0 py-[9px] leading-none px-[10px] text-[#A09992] dark:text-[#FFFFFF] bg-transparent w-full lg:w-1/2"
                  placeholder="username"
                  onChange={(e) => {
                    const lowercaseUsername = e.target.value.toLowerCase();
                    setNewUsername(lowercaseUsername);
                    setUsernameState(true);
                    handleUsernameChange(lowercaseUsername);
                    setIsConfirmed(false);
                  }}
                  onClick={() => changeText("Save")}
                  value={newUsername || ""}
                  defaultValue={userInfo?.username}
                />

                {usernameState && newUsername !== userInfo?.username ? (
                  isChecking ? null : (
                    <img
                      height={20}
                      width={20}
                      className="object-contain absolute right-[9px] top-[50%] -translate-y-1/2"
                      // src={isAvailable ? ((newUsername.length >= 3 && newUsername.length <= 4) ? purple_tick : greenCheck) : redCross}
                      // alt={isAvailable ? "greenCheck" : "redCross"}

                      src={isAvailable ? (isFreeUsername ? greenCheck : purple_tick) : redCross}
                      alt={isAvailable ? (isFreeUsername ? "greenCheck" : "purple_tick") : "redCross"}
                    />
                  )
                ) : (
                  <span className=""></span>
                )}
              </div>
            </div>

            <div className="flex flex-row justify-between w-1/2">
              <button
                onClick={() => {
                  changeUserUsername(newUsername);
                }}
                className={
                  isConfirmed
                    ? "flex items-center my-[20px] border border-[#5FC650] text-[white] bg-[#5FC650] p-[10px] px-[8px] rounded cursor-pointer font-semibold leading-none text-base "
                    : "flex items-center my-[20px] border border-[#5FC650] text-[#5FC650] pl-[10px] p-[10px] rounded cursor-pointer font-semibold leading-none text-base "
                }
              >
                {isConfirmed && userInfo.username ? "Confirmed" : "Confirm"}
                {isConfirmed && (
                  <img
                    className="w-[16px] ml-[8px]"
                    src={tickCirlce}
                    alt="tick"
                  />
                )}
              </button>
            </div>
          </div>
        </div>

        {isAuthPremium && <QrCodeModal></QrCodeModal>}
        {/* {================================================ start: Privacy ========================================= } */}
        <div>
          <h2 className="mt-7 leading-[24.42px] text-left md:text-xl text-lg font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
            Privacy
          </h2>
          <p className=" mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-white  my-5">
            External search
          </p>
          <div className="flex flex-col border border-[#EAEAEA] rounded mt-[20px]">
            <div className="flex flex-row justify-between  items-center p-[10px]">
              <p className="text-base font-regular text-[#645F5B] dark:text-white mr-[5px] sm:mr-[20px]">
                Instruct search engines to ignore your Layer
              </p>

              <Switch
                checked={isPrivate}
                size={36}
                width={36}
                height={24}
                onChange={(checked) => {
                  setIsPrivate(checked);
                  dynamicRobotsTxtManager(checked);
                }}
              />
            </div>
            {/* <Helmet>
              <meta name="robots" content={isPrivate ? 'noindex,nofollow' : 'index,follow'} />
            </Helmet> */}
          </div>
          <div>
            <p className=" mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-white  my-5">
              Blocked users
            </p>
            <div
              onClick={() => loadBlockUsers()}
              className="inline-flex w-auto border border-[#EAEAEA] rounded"
            >
              <div className="inline-flex w-auto flex-row justify-between  items-center px-[11px] py-[9px]">
                <p className="text-base font-regular text-[#645F5B] dark:text-white mr-[5px] sm:mr-[20px] cursor-pointer text-sm">
                  Edit blocked Layers
                </p>
              </div>
            </div>
            {/* ///////////////////////// message permissions ////////////////// */}

            <div>
              <p className=" mt-10  leading-[19px] text-left text-base text-[#645F5B] dark:text-white  my-5">
                Messaging permissions
              </p>
              <div className="flex flex-col lg:flex-row justify-between mt-5">
                <Dropdown
                  className=" w-full lg:w-[450px]"
                  menuClassName="rounded p-2 text-sm font-semibold mt-[5px]"
                  controlClassName="flex justify-between px-[10px] py-[9px] bg-[#EAEAEA] border-none rounded-md hover:bg-[#E2E2E2] active:bg-[#CCC] leading-none"
                  placeholderClassName="font-[400] text-[#252423]"
                  arrowClassName="dropdownArrow down border-[#645F5B]"
                  arrowClosed={<span className="dropdownArrow down border-[#645F5B]" />}
                  arrowOpen={<span className="dropdownArrow down border-[#645F5B]" />}
                  options={dropdownOptions}
                  onChange={handleSelect}
                  value={selectedOption}
                />
              </div>
            </div>

            <div>
              <Modal
                isOpen={blockModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeBlockModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <>
                  <div
                    className=" w-[500px] max-w-full bg-[#fff] h-[auto] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]
                     border-[#EAEAEA] border-[1px] radius-[10px] rounded-[10px]"
                  >
                    <div className="mb-[20px] leading-none  text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
                      Blocked Layers
                    </div>
                    <div className="font-normal text-base  mb-[10px] leading-none text-[#645F5B] dark:text-[#fff] ">
                      Edit your blocked Layers.
                    </div>

                    <>
                      <div className="overflow-y-auto h-[228px] mt-[40px] bookmarkModal__scrollList">
                        {blockUser &&
                          blockUser.map((userWithName, index) => (
                            <>
                              <div
                                key={index}
                                className=" flex flex-row justify-between relative text-base outline-0 text-[#645F5B] dark:text-white w-full px-[2px] py-[12px] placeholder-[#fff] border-[#EAEAEA] h-[44px] border-t-[1px] bookmarkModal__item"
                              >
                                <span>@{userWithName.username}</span>

                                <button
                                  type="button"
                                  className={
                                    " w-[59px] h-[20px] border border-[#E1655C] text-[#E1655C] px-[0px] py-[2px] rounded cursor-pointer font-semibold leading-none text-xs "
                                  }
                                  onClick={() => {
                                    unblockUser(userWithName.id);
                                  }}
                                >
                                  Unblock
                                </button>
                              </div>
                            </>
                          ))}
                      </div>
                      <div className="relative border-solid border-t-[1px] border-[#EAEAEA] mt-[20px] mx-[-20px] pt-[20px] px-[20px]">
                        <input
                          type="text"
                          className="bg-[#D6D6D633] focus:border-[#0A85D1]  px-2 text-[#645F5B] dark:text-[#fff] text-base outline-0 text-base  h-[35px] rounded w-full p-[10px] cursor-pointer placeholder-[#645F5B] dark:placeholder-[#fff] border-solid border-[1px] border-[#EAEAEA]"
                          placeholder="Search"
                          onKeyUp={(e) => searchBlockUser(e.target.value)}
                          onKeyDown={(e) => searchBlockUser(e.target.value)}
                        />
                        <svg
                          className="cursor-pointer dark:text-[#fff] text-[#645F5B] mt-[10px] w-[15px] h-[15px] absolute right-[30px] top-[50%] translate-y-[-50%]"
                          aria-hidden="true"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </div>
                    </>

                  </div>
                </>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      <CropImageModal
        isVisible={isCropPopup}
        selectedImage={
          (selectedImage && URL?.createObjectURL(selectedImage)) || ""
        }
        onClose={() => setCropPopup(false)}
        onEditComplete={onEditComplete}
        onCancel={() => {
          setCropPopup(false);
          setSelectedImage(null);
        }}
      />
    </>
  );
};

export default SettingsProfile;
